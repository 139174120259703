//  Libs
import React, { useState, useContext, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// Context
import { HeaderContext } from '../../../context/header';
import { FooterContext } from '../../../context/footer';

// Components
import Layout from 'components/layout';
import SEO from 'components/seo';
import { device } from 'components/device';
import BreadCrumb from 'components/breadCrumb';
import Menu from 'components/contactUs/menu';

import DescriptionPage from 'components/contactUs/descriptionPage';
import Contact from 'components/contactUs/contact';
import HeaderIntern from 'components/contactUs/headerIntern';

//Image
import Icon from 'images/icons/arrow-up.svg';
import brazil from 'images/icons/flags/brazil.svg';
import unitedstates from 'images/icons/flags/unitedstates.svg';
// import spain from 'images/icons/flags/spain.svg';

// Styles
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 30px;

  code {
    margin-top: 1.375rem;
    font-size: unset;
    font-family: unset;
    color: #8f9194;
    background: unset;
  }

  @media ${device.laptop} {
    display: block;
    overflow-x: hidden;
  }
`;

const Wrapper = styled.div`
  grid-column: 2 / -2;
`;

const ContainerContent = styled.div`
  display: inline-grid;
  grid-column: 5 / 11;
  flex-direction: column;

  section {
    > :first-child {
      padding-top: 20px;

      @media ${device.mobile320} {
        em {
          line-height: 1.5rem;
        }
      }
    }
    line-height: 0;
    img {
      :first-child {
        position: relative;
        top: 20px;
      }
    }

    p {
      em {
        position: relative;
        top: 0.1875rem;
        padding: 0;
        color: #8f9194;
        line-height: 3rem;
      }

      a {
        :nth-child(3n) {
          line-height: 3rem;
        }
      }
      :last-child {
        margin-bottom: 0;
      }
    }
  }

  @media ${device.laptop} {
    position: relative;
    padding: 0 20px;
    display: flex;

    p {
      width: 100%;
      font-size: 14px;
      em {
        padding: 0;
        font-size: 14px;
        color: #8f9194;
      }
    }
  }

  @media ${device.mobile375} {
    h1 {
      width: 83%;
    }
  }
`;

const ContainerFlags = styled.div`
  position: relative;
  margin-bottom: 1.25rem;
  width: 3.938rem;
  min-height: 2.313rem;
  border-radius: 8px;

  @media ${device.laptop} {
    position: absolute;
    top: 1.5rem;
    right: 1.25rem;
  }
`;

const ContentFlags = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 0.625rem;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid #c6c8cc;
  border-radius: 8px;
`;

const ContentModal = styled.div`
  display: flex;
  flex-direction: ${props => props.isColumn && 'column'};
  cursor: pointer;
`;

const IconIsModal = styled.img`
  transform: ${props => (props.isModal ? 'rotate(360deg)' : 'rotate(180deg)')};
  cursor: pointer;
`;

const IconFlag = styled.img`
  margin: ${props => props.spain && '1.875rem 0'};
  width: 1.5rem;
  cursor: pointer;

  :hover {
    background-color: #c6c8cc38;
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, isIdiom) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const translator =
      isIdiom && isIdiom === 'spain'
        ? flatten(findFragment.localizations)[0].markdown
        : isIdiom && isIdiom === 'unitedstates'
        ? flatten(findFragment.localizations)[0].localizations[0].markdown
        : flatten(findFragment.localizations)[0].localizations[0]
            .localizations[0].markdown;

    return translator;
  } else {
    return flatten(findFragment.localizations);
  }
};

const InternationalPatienceAssistance = ({ page, location }) => {
  const [isModal, setModal] = useState(false);
  const [isIdiom, setIdiom] = useState('unitedstates');
  const [flags] = useState([
    { name: 'unitedstates', alt: 'United States', img: unitedstates },
    // { name: 'spain', alt: 'Spain', img: spain },
    { name: 'brazil', alt: 'Brazil', img: brazil },
  ]);

  const { setHasDefaultHeader, setCallCenterBackLink } = useContext(
    HeaderContext
  );

  const { setHasFooter } = useContext(FooterContext);

  useEffect(() => {
    if (window.innerWidth <= 1024) {
      setHasDefaultHeader(false);
      setCallCenterBackLink('/fale-conosco/');
      setHasFooter(false);
    }
  }, []);

  const handleIsModal = () => {
    setModal(!isModal);
  };

  const handleSetIdiom = item => {
    setIdiom(item);
  };

  const renderSelectedLanguage = () => {
    const flagsSelected =
      isIdiom === 'unitedstates'
        ? unitedstates
        : isIdiom === 'brazil'
        ? brazil
        : // : isIdiom === 'spain'
          // ? spain
          unitedstates;
    const flagsFilter = flags.filter(flag => flag.name !== isIdiom);

    return (
      <ContainerFlags isModal={isModal} onClick={handleIsModal}>
        <ContentFlags isModal={isModal}>
          <ContentModal isModal={isModal}>
            <IconFlag src={flagsSelected} alt="Brazil" modal={isModal} />
            <IconIsModal isModal={isModal} src={Icon} alt="Abrir Modal" />
          </ContentModal>
          {isModal && (
            <ContentModal isModal={isModal} isColumn>
              {flagsFilter.map((i, index) => (
                // eslint-disable-next-line react/jsx-key
                <IconFlag
                  src={i.img}
                  alt={i.name}
                  modal={isModal}
                  // spain={index === 0}
                  onClick={() => handleSetIdiom(i.name)}
                />
              ))}
            </ContentModal>
          )}
        </ContentFlags>
      </ContainerFlags>
    );
  };

  const pathname = location.pathname;

  return (
    <Layout>
      <HeaderIntern />
      <SEO dataSeo={page} />
      <GridContainer>
        <Wrapper>
          <BreadCrumb
            markdown={getMarkdown(
              page,
              'cki9cc7uw09su097390frc2v5',
              true,
              isIdiom
            )}
            marginTop
          />
        </Wrapper>
        <ContainerContent>
          {renderSelectedLanguage()}
          <DescriptionPage
            markdown={getMarkdown(
              page,
              'cki9cfets09tp0a77oa2bka37',
              true,
              isIdiom
            )}
            widthLarge
            interScreen
          />
          <Contact
            markdown={getMarkdown(
              page,
              'cki9clwmg0ak60a78xdlx64hx',
              true,
              isIdiom
            )}
            padding=".97rem 1.25rem"
            noLineHeight
          />
          <Menu intern pathname={pathname} />
        </ContainerContent>
      </GridContainer>
    </Layout>
  );
};

export default ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "cki9b84yg06u40a78vanoag2f" }) {
                fragments {
                  id
                  name
                  localizations {
                    markdown
                    singletexts
                    localizations(locales: en) {
                      markdown
                      singletexts
                      localizations(locales: pt_BR) {
                        singletexts
                        markdown
                      }
                    }
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return (
          <InternationalPatienceAssistance
            location={location}
            page={response.gcms.site.pages}
          />
        );
      }}
    />
  );
};
